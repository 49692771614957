export default [
    {
        path: '/reports/sales',
        name: 'sales',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Sales.vue')
    },
    {
        path: '/reports/card-sales',
        name: 'card-sales',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/CardSales.vue')
    },
    {
        path: '/reports/locations',
        name: 'locations',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Locations.vue')
    },
    {
        path: '/reports/neighborhood',
        name: 'neighborhood',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Neighborhood.vue')
    },
    {
        path: '/reports/clients',
        name: 'clients-report',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Clients.vue')
    },
    {
        path: '/reports/products',
        name: 'products-report',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Products.vue')
    },
    {
        path: '/reports/assortment',
        name: 'assortment',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Assesors.vue')
    },
    {
        path: '/reports/wiks-points',
        name: 'points-report',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/reports/Points.vue')
    }
]