import sendRequest from "@/libs/sendRequest";

export const points_operations = {
    namespaced: true,
    state: {
        operation: null,
        operations: []
    },
    getters: {
        getOperation: (state) => (state.operation),
        getOperations: (state) => (state.operations)
    },
    mutations: {
        setOperation: (state, payload) => (state.operation = payload),
        setOperations: (state, payload) => (state.operations = payload)
    },
    actions: {
        list: async({commit, rootGetters}) =>{
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/wikspoints/operations/orders', 'GET', null, null, custom_headers);
            
            if(response.success  && response.data) {
                let raw_data = response.data.list ?? [];
                
                raw_data = raw_data.map((item) => {
                    return {
                        ...item,
                        actions: '-',
                    }
                })
                
                commit('setOperations', raw_data);
            }
            
            else if(!response.success  && response.errors) {
                console.log(response.errors);
            } 
            
            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API');
            }
        },
        view: async({commit, rootGetters}, reference) =>{
            let custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/wikspoints/operations/detail', 'GET', null, null, reference, custom_headers);
            
            if(response.success && response.data) {
                response.data.list.Productos = JSON.parse(response.data.list.Productos); 
                
                commit('setOperation', response.data.list);
            }
            else if(!response.success  && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API');
            }
        }
    }

}