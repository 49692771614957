import { driver } from 'driver.js' 
import 'driver.js/dist/driver.css'

export default (app) => {

    const driveObj = driver()

    const methods = {
        //{ element: '#tour-example', popover: { title: 'Animated Tour Example', description: 'Here is the code example showing animated tour. Let\'s walk you through it.', side: "left", align: 'start' }},
        animated: (options = {}) => {

            if(Object.keys(options).length == 0) {
                return
            }
            const driveObj = driver(options)
            driveObj.drive()
        }
    }

    app.provide('$driver', methods)
}
