import sendRequest from "@/libs/sendRequest";

export const points_settings = {
    namespaced: true,
    state: {
        settings: [],
        setting: {},
        error: false,
    },
    mutations: {
        setSettings: (state, payload) => (state.settings = payload),
        setSetting: (state, payload) => (state.setting = payload),
        setError: (state, payload) => (state.error = payload),
    },
    getters: {
        getSettings: (state) => (state.settings),
        getSetting: (state) => (state.setting),
        getError: (state) => (state.error),
    },
    actions: {
        list: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/settings/list', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                commit('setSettings', response.data.list ?? []);
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        view: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/settings/view', 'GET', null, null, {id: id}, custom_headers)

            if(response.success && response.data) {
                commit('setSetting', response.data.list ?? {});
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        add: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/settings/add','POST', null, data, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Configuración añadida con éxito.'},
                    {root: true})
                commit('setError', false);

            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_porcentaje':
                            messages.push("porcentaje requerido");
                            break;
                        case 'wrong_or_empty_inicio':
                            messages.push("Fecha de inicio requerido");
                            break;
                        case 'wrong_or_empty_fin':
                            messages.push("Fecha de termino requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        update: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/settings/update','PUT', null, data.body, data.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Settingo editado con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_porcentaje':
                            messages.push("porcentaje requerido");
                            break;
                        case 'wrong_or_empty_inicio':
                            messages.push("Fecha de inicio requerido");
                            break;
                        case 'wrong_or_empty_fin':
                            messages.push("Fecha de termino requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdío la conexión con la API');
            }
        },
        delete: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/settings/active', 'DELETE', null, null, {id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Configuración eliminada con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: 'Un error ha ocurrido intente más tarde.'},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        }
    },
}