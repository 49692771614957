import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { driver } from '@/plugins'

// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'

createApp(App)
.use(store)
.use(router)
// import GLOBAL COMPONENTS
import { Input, Spinner, Table, Modal, Alert, Select, DropDown, Loader } from './components/index.js'


// TEMPLATES
import T_default from 'TEMPLATES/default'
import T_session from 'TEMPLATES/session'

//libs
import _Lodash from 'lodash'
import _sendRequest from '../src/libs/sendRequest'


// Create App
const app = createApp(App)

app.use(store)
app.use(router)


import VueGoogleMaps from '@fawmi/vue-google-maps'
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDvCCUv6q09IKaX0a8Mr2B66izt9nSLuw0'
    },
})

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatePicker', VueDatePicker)

//plugins
app.use(driver)


// templates
app.component('default', T_default)
app.component('session', T_session)
// components
app.component('ws-input', Input)
app.component('ws-modal', Modal)
app.component('ws-table', Table)
app.component('ws-spinner', Spinner)
app.component('ws-select', Select)
app.component('ws-alert', Alert)
app.component('ws-dropdown', DropDown)
app.component('ws-loader', Loader)
// libs
app.provide('$_', _Lodash)
app.provide('$sendRequest', _sendRequest)


app.mount('#app')
