import sendRequest from "@/libs/sendRequest";
export const points_category = {
    namespaced: true,
    state: {
        categories: [],
        category: {},
        error: false,
    },
    mutations: {
        setCategories: (state, payload) => (state.categories = payload),
        setCategory: (state, payload) => (state.category = payload),
        setError: (state, payload) => (state.error = payload),
    },
    getters: {
        getCategories: (state) => (state.categories),
        getCategory: (state) => (state.category),
        getError: (state) => (state.error),
    },
    actions: {
        list: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/wikspoints/category/list', 'GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setCategories', response.data.list ?? []);
            } 
            
            else if(!response.success && response.errors) { 
                console.log(response.errors);
            }
            
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        view: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/category/view', 'GET', null, null, {id: id}, custom_headers)

            if(response.success && response.data) {
                commit('setCategory', response.data.list ?? {});
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        add: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/wikspoints/category/add','POST', null, data, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Categoria añadida con éxito.'},
                    {root: true})
                commit('setError', false);
                
            }

            else if(!response.success && response.errors) {
                let messages = [];
                
                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_nombre':
                            messages.push("El campo nombre es requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        update: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/category/update','PUT', null, data.body, data.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Categoria editada con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_nombre':
                            messages.push("El campo nombre es requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        delete: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/category/active', 'DELETE', null, null, {id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Categoria eliminada con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: 'Un error ha ocurrido intente más tarde.'},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        }
    },
}