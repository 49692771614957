export default [
    {
        path: '/catalogues/products',
        name: 'products',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Products.vue')
    },
    {
        path: '/catalogues/categories',
        name: 'categories',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Categories.vue')
    },
    {
        path: '/catalogues/families',
        name: 'families',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Families.vue')
    },
    {
        path: '/catalogues/raw-products',
        name: 'raw-products',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/RawProducts.vue')
    },
    {
        path: '/catalogues/notifications',
        name: 'notifications',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Notifications.vue')
    },
    {
        path: '/catalogues/banners',
        name: 'banners',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Banners.vue')
    }

]