<template>
  	<div id="app">
    	<ws-alert />
   		<component :is="layout || 'section'">
      		<router-view />
    	</component>
  	</div>
</template>

<script setup>
	import { computed } from "vue";

	import { useRouter } from "vue-router";

	//hooks
	const router = useRouter();

	//computed
	const layout = computed(() => {
		return router.currentRoute.value.meta.layout
	});
	console.log('template:', layout.value)
</script>


<style lang="scss">
    * {
        scrollbar-color:  #2A388F transparent;
        scrollbar-width: thin;
    }

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
        
        
	}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
		margin: 0;
	}
</style>
