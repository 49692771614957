module.exports = {
	env: "dev",
	server: {
		local: {
			page_url: 'http://www.wiks-services.online/',
			api: 'http://localhost:4000',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.app-customer.ferguez",
				'Authorization': 'Bearer $2b$10$.ttwE7fHbqwHUBAWNaQw6u8JKKc08MRErsIlYuq0jY8zpWyvsFAAK',
				"session": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiY3VzdG9tZXJfaWQiOjEsImZpcnN0X25hbWUiOiJSYW1vbiIsImxhc3RfbmFtZSI6IkphcmFtaWxsbyIsInVzZXJuYW1lIjoicmFtb24uamFyYW1pbGxvIiwiZW1haWwiOiJqcmljb0Btb25vY2lsaW5kcmVyby5jb20iLCJwaG9uZSI6IjUyMzMyMjIzODg4NiIsImN1c3RvbWVyIjoidGVzdGluZyIsInJlZmVyZW5jZV9pZCI6IjAwMDAxMDU0Iiwic2NoZW1lc19jbGllbnRlIjpbeyJFc3F1ZW1hSUQiOiJMUDE1IiwiRXNxdWVtYUlEUGFkcmUiOiJMUCIsIk5vbWJyZSI6IlBSRUNJT1MgUFVCTElDTyBFTiBHRU5FUkFMIiwiUHJlY2lvQ2xhdmUiOiIxNSJ9XSwiaWF0IjoxNjU5MzgxMjI3LCJleHAiOjE2NTkzODQ4Mjd9.oVyG37R_moz9fZj_mzfkGXlmUTnUBfPiGroV5bxFWzc",
				"order": "K67GCQRGBPGHZ8BWW3ZH"
			}
		},
		dev: {
			page_url: 'http://www.wiks-services.online',
			api: 'https://qa-api.wiks-services.online',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.app-customer.ferguez",
				'Authorization': 'Bearer $2b$10$.ttwE7fHbqwHUBAWNaQw6u8JKKc08MRErsIlYuq0jY8zpWyvsFAAK',
				"session": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiY3VzdG9tZXJfaWQiOjEsImZpcnN0X25hbWUiOiJSYW1vbiIsImxhc3RfbmFtZSI6IkphcmFtaWxsbyIsInVzZXJuYW1lIjoicmFtb24uamFyYW1pbGxvIiwiZW1haWwiOiJqcmljb0Btb25vY2lsaW5kcmVyby5jb20iLCJwaG9uZSI6IjUyMzMyMjIzODg4NiIsImN1c3RvbWVyIjoidGVzdGluZyIsInJlZmVyZW5jZV9pZCI6IjAwMDAxMDU0Iiwic2NoZW1lc19jbGllbnRlIjpbeyJFc3F1ZW1hSUQiOiJMUDE1IiwiRXNxdWVtYUlEUGFkcmUiOiJMUCIsIk5vbWJyZSI6IlBSRUNJT1MgUFVCTElDTyBFTiBHRU5FUkFMIiwiUHJlY2lvQ2xhdmUiOiIxNSJ9XSwiaWF0IjoxNjU5MzgxMjI3LCJleHAiOjE2NTkzODQ4Mjd9.oVyG37R_moz9fZj_mzfkGXlmUTnUBfPiGroV5bxFWzc",
				"order": "K67GCQRGBPGHZ8BWW3ZH"
			}
		},
		prod: {
			page_url: 'http://www.wiks-services.online/',
			api: 'https://api.wiks-services.online',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.app-customer.ferguez",
				'Authorization': 'Bearer $2b$10$.ttwE7fHbqwHUBAWNaQw6u8JKKc08MRErsIlYuq0jY8zpWyvsFAAK',
				"session": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiY3VzdG9tZXJfaWQiOjEsImZpcnN0X25hbWUiOiJKYWltZSIsImxhc3RfbmFtZSI6IlJpY28iLCJ1c2VybmFtZSI6ImphaW1lLnJpY28iLCJlbWFpbCI6ImpyaWNvQG1vbm9jaWxpbmRyZXJvLmNvbSIsInBob25lIjoiNTIzMzIyMjM4ODg2IiwiY3VzdG9tZXIiOiJ0ZXN0aW5nIiwicmVmZXJlbmNlX2lkIjoiMDAwMDMzNzMiLCJzY2hlbWVzX2NsaWVudGUiOlt7IkVzcXVlbWFJRCI6IkxQMTUiLCJFc3F1ZW1hSURQYWRyZSI6IkxQIiwiTm9tYnJlIjoiUFJFQ0lPUyBQVUJMSUNPIEVOIEdFTkVSQUwiLCJQcmVjaW9DbGF2ZSI6IjE1In1dLCJpYXQiOjE2NTkyMTcxODYsImV4cCI6MTY1OTIyMDc4Nn0.HYXs-fP9RYKU6uxlQYeiT7zeufB2ziGTY4eHHvlRkGc",
				"order": "3HCE1L2F631QEDGMSRNH"
			}
		},
	},
	class_colors: ['primary',	'primary-light', 'primary-dark', 'secondary', 'secondary-light', 'secondary-dark', 
		'success', 'success-light', 'success-dark', 'warning', 'warning-light', 'warning-dark', 'danger', 
		'danger-light', 'danger-dark', 'info', 'info-light', 'info-dark', 'light', 'light-light', 'light-dark', 
		'dark', 'dark-light', 'dark-dark'],
}