export default [
    {
        path: '/wikspoints/categories',
        name: 'points-categories',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/points/Categories.vue')
    },
    {
        path: '/wikspoints/settings',
        name: 'points-settings',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/points/Settings.vue')
    },
    {
        path: '/wikspoints/products',
        name: 'points-products',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/points/Products.vue')
    },
]